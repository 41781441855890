var operatonBases = { 
    map: {},
    markers: [],
    mapSelector: document.getElementById( 'mapa-statkow' ),
    images:[],
    ports: [],
    infoWindow: null,

    _init: function() { 
        var self = this;
        this.setImages();

        var selects = $('body').find('.port-select');
        if(this.mapSelector){
            this.map = this.mapInit(this.mapSelector),{

            }
        }
        this.getPorts();
        $(selects).on('change', function() {

            var portSelect = $('body').find('#choose-port').find(':selected').attr('data-value');
            var portType = $('body').find('#choose-type').find(':selected').attr('data-value');
            
            if(portSelect == undefined) { 
                portSelect = '';
            }
            if((portType == undefined) || (portType == 'Wszystkie') ) { 
                portType = '';
            }
            self.loadCompanies( { type: portType, port: portSelect  } );
        });
        this.getUrlParams();
    },


    getPorts:function(){
        this.clearAllMarkers();
        var options = $('#choose-port').find('option');
        var self = this;
        options.map(function(index){ 
            self.ports.push({value: $(options[index]).attr('data-value'), label: options[index].value })
        });
    },

    getUrlParams:function() {
        var url = window.location.toString();
        var params = ''

        if(url.includes('=')) {
            params = url.split('=').slice(-1).pop();
        }
        if(params.length > 0){ 
            this.loadCompanies( { type: '', port: params == 'undefined' ? 'port-gdansk' : params   } );
            var selectValue = '';
            this.ports.map(function(port){

                if(port.value == params) {
                    selectValue = port.label;
                };
            });

                $('body').find('#choose-port').select2().val(selectValue).trigger("change");
        } else { 
            this.loadCompanies({type: '', port: 'port-gdansk'});

        }
    },
    setImages:function(){
        var self = this;
        var portTypeOptions = $('body').find('#choose-type').find('option');

        $(portTypeOptions).each(function(){
            self.images.push({
                name: $(this).attr('data-value'),
                img: $(this).attr('data-img'),
                logoCompany: $(this).attr('data-imgCompany')
            });
        });
    },

    loadCompanies: function(paramsObj, url = '')  {
        var spinner = $('body').find('#pcs-spinner');
        var self = this;
        var markerLocalArray = [];
        var dataOutput = $('body').find('.companies-wrapper');
        var textObj = {};



        if(!url){
            this.clearAllMarkers();
            $(spinner).addClass('visible');
        }
        this.clearAllMarkers(); 





         $.get('ajax/company'+ url, paramsObj , function (data) {
            $(dataOutput).empty();


            $(dataOutput).append(data);
            self.infoWindow = new google.maps.InfoWindow();
            $(dataOutput).find('.markers-reference').each(function() {
                var lat = $(this).attr('data-lat');
                var lng = $(this).attr('data-lng');
                var type = $(this).attr('data-port-type');
                textObj = { 
                    title: $(this).find('.title').text(),
                    adress:  $(this).find('.adress').text(),
                    fax: $(this).find('.fax').text(),
                    phone: $(this).find('.phone').text(),
                    email: $(this).find('.email').text(),
                    // logo: $(this).find('.logo').attr('logoData'),
                    www:'http://' +  $(this).find('.www').attr('wwwData'),
                };
                // console.log(textObj.logo)
                markerLocalArray.push({   
                    location : new google.maps.LatLng( parseFloat(lat), parseFloat(lng) ), 
                    type: type,
                    html: self.buildHtml(textObj) });
                });

        }).done( function(data) {
             self.clearAllMarkers();
            self.map.setCenter(markerLocalArray[0].location);
            self.map.setZoom(9);
            self.setMarkers(markerLocalArray);

            self.goToPage(paramsObj);
            self.getPositionOfCompany();
            self.getNumberOfSearchingResults();
            main.transformSVGToInline();
            $(spinner).removeClass('visible');
        });

    },

    sizeOfMarkers: function(){
        marker = new google.maps.Marker({
            icon: {

                size: new google.maps.Size(25, 25),
                scaledSize: new google.maps.Size(25, 25)
            }
        });
    },

    buildHtml: function(info) {
        console.log(info.logo)
        const webAdress = info.www;
        const webName = webAdress === 'http://' ? ' ' : 'strona internetowa';

        // <img id="logoItem" class="logo" src="${info.logo}"><img>
        return` 
        
       <div class="object-data" style="flex-direction: column;align-items: flex-start">  
        
        
        <div class="title">${info.title} </div> 
        <div class="desc">${info.adress }</div> 
        <div class="desc"><a id="faxItem" class="newItem" href="tel:${info.fax}"> ${info.fax} </a></div> 
        <div class="desc"><a id="phoneItem" class="newItem" href="tel:${info.phone}">${info.phone}</a></div> 
        <div class="desc"><a id="mailItem" class="newItem" href="mailto:${info.email} ">${info.email}</a></div> 
        <div class="desc"><a id="wwwItem" class="newItem" target="_blank" href="${info.www} ">${webName}</a></div> 

        </div> 
         
        `
    }, 

    goToPage:function(currentParamsObj) { 
        var self = this;

        var pages = $('body').find('#company-pagination a');
        $(pages).on('click', function() {
            var page = $(this).attr('data-page');
            var url = '/p' + page.toString();
            self.setImages();
            self.loadCompanies(currentParamsObj, url);

        });
    },

    getPositionOfCompany:function(){
        var self = this;

        $('body').find('.map-marker').on('click', function(event) {
            var lat = $(event.target).closest('.company-wrapper ').attr('data-lat');
            var lng = $(event.target).closest('.company-wrapper ').attr('data-lng');
            var textObj = { 
                title: $(event.target).closest('.company-wrapper').find('.title').text(),
                adress:  $(event.target).closest('.company-wrapper').find('.adress').text(),
                fax: $(event.target).closest('.company-wrapper').find('.fax').text(),
                phone: $(event.target).closest('.company-wrapper').find('.phone').text(),
                email: $(event.target).closest('.company-wrapper').find('.email').text(),
                // logo: $(event.target).closest('.company-wrapper').find('.logo').attr('imgData'),
                www:'http://' + $(event.target).closest('.company-wrapper').find('.www').attr('wwwData')
            };
            self.zoomToElement(parseFloat(lat),parseFloat(lng), textObj );
            self.scrollToMap(self.mapSelector);
        });

    },

    getNumberOfSearchingResults: function(){
        var number = $('body').find('.markers-reference').length;
        $('#company-search-result').text(number.toString());
    },

    zoomToElement: function(lat, lng, textObj  ){
        var self = this;
        var html = this.buildHtml(textObj)
        self.map.setCenter(new google.maps.LatLng(lat, lng));
        self.map.setZoom(14);

        this.openInfoWindow( lat, lng, html);
    },

    openInfoWindow: function(lat, lng, html) {
        this.infoWindow.close();
        this.infoWindow.setContent( html );
        this.infoWindow.open( this.map );
        this.infoWindow.setPosition(new google.maps.LatLng(lat + 0.0010, lng));
    },

    bindInfoWindow : function ( html, marker ) {
        var self = this;

        marker.addListener( 'click', function() {
        
            self.infoWindow.close();
            self.infoWindow.setContent( html );
            self.infoWindow.open( self.map, this );
           
        });
    }, 

    setMarkers: function(markersArray){
        var self = this;
        markersArray.forEach(function (element) {
            self.addMarker(element);
        });

    },

    clearAllMarkers: function(){
        if (this.markers) {
            for (i in this.markers) {
              this.markers[i].setMap(null);
            }
        }
    },

    addMarker: function(markerObj) {
        var self = this;
        var image = self.getImage(markerObj.type)[0].img;






        if(image) {

            marker = new google.maps.Marker({
                position: markerObj.location,
                map: this.map,
                icon: { 
                    url: image,
                    size: new google.maps.Size(25, 25),
                    scaledSize: new google.maps.Size(25, 25)
                } 
            });
            this.markers.push(marker);
            self.bindInfoWindow(markerObj.html, marker);
        }
    },

    getImage:function(key){
        return this.images.filter(function(element){ 
            return element.name == key;
        });
        
    },
};

$(document).ready(function(){
    Object.setPrototypeOf(operatonBases, map);
    if(operatonBases.mapSelector) {
        operatonBases._init();
    }
});



