var popupCareer = {

    showPopupSelector: '.apply-btn',
    popupSelector: '.career-popup .pop-up',
    exitButton : '.career-popup .pop-up .exit-cross',
    _init: function() {

        this.registerEvents();
        this.hidePopup();
        this.getDocumentName();

    },

    registerEvents: function() {

        var self = this;

        $( this.showPopupSelector ).click(function(event){ 
            var target = $( event.target );           
            self.showPopup(target);
        });

        $(document).mouseup(function(e)  {

            var container = $(".popup-wrapper");

            if (!container.is(e.target) && container.has(e.target).length === 0) {
                self.hidePopup();                
            }

        });
    
        $(document).keyup(function(e){

            if(e.keyCode === 27) {
                self.hidePopup();    
            }

        });

    },
    
    showPopup: function(target) {
        
        $(this.popupSelector).fadeIn("slow");

        var title = target.closest('.one-offer ').find('a .bar .offer-headers h2').text().trim();
        $(this.popupSelector).find( '.popup2 .element-content-box .main-header .text-header .sub-header h1' ).text( title );

        
        var number = target.closest('.one-offer ').find('a .bar h1').text().trim();
        $(this.popupSelector).find( '.main-header .number' ).text( number );


        var inputTitle = target.closest('.one-offer ').find('a .bar .offer-headers h2').text().trim();
        $(this.popupSelector).find( '.contact-form-wrapper input[name=subject]' ).text( inputTitle );

    },

    hidePopup: function() {

        $( this.exitButton ).click(function(){  
         
            $('.career-popup .pop-up').fadeOut('slow');
        });

    },


    getDocumentName: function() {
        var documentCvName = $('.career-popup .pop-up .popup-media-scroll .attachements-elements .elements .add-file-elements input#cv');

        var documentLmName = $('.career-popup .pop-up .popup-media-scroll .attachements-elements .elements .add-file-elements input#lm');

        $(document).ready(function(){
            documentCvName.change(function(e){
                var fileName = e.target.files[0].name;
                
                $('#cv-label').html(fileName);
            });
            documentLmName.change(function(e){
                var lmName = e.target.files[0].name;
                $('#lm-label').html(lmName);
            });
        });


    },

};

$( document ).ready( function() {
    popupCareer._init();
});

// $(document).ready(function(){
    


// });

