
var script = {
  
  _init: function() {  
    this.startSlider();
  },
  
  startSlider: function() {
    var owl = $('.owl-carousel');
    var pauseBtn = $(".circle-loader-button");
    var playBtn = $(".circle-loader-button-behind");
    var loader = $(".loader");
    $(".main-carousel").owlCarousel({
      loop:false,
      rewind: true,
      margin:0,
      nav:false,
      dots:true,
      dataDots:true,
      autoplay: true,
      autoplayTimeout: 6000,
      // animateOut: 'fadeOut',
      responsive:{
        0:{
          items:1
        },
      }
    });

    $('.partners-slider').owlCarousel({
      loop:false,
      rewind: true,
      margin:0,
      nav:false,
      dots:true,
      dataDots:true,
      autoplay: true,
      autoplayTimeout: 6000,
      items: 4
    });

    pauseBtn.on('click', function() {
      owl.trigger('stop.owl.autoplay');
      pauseBtn.addClass("slider-button-behind");
      pauseBtn.removeClass("slider-button-front");
      playBtn.addClass("slider-button-front");
      playBtn.removeClass("slider-button-behind");
      loader.addClass("paused");
      
    });
    playBtn.on('click', function() {
      owl.trigger('play.owl.autoplay', [5000]);
      playBtn.addClass("slider-button-behind");
      playBtn.removeClass("slider-button-front");
      pauseBtn.addClass("slider-button-front");
      pauseBtn.removeClass("slider-button-behind");
      loader.removeClass("paused");
    });

    var dot = $('.owl-carousel .owl-dot');
    dot.each(function() {
      var index = $(this).index();
      $(this).html(index + 1 + "°");
      
    });

  },
};

$(document).ready(function() {
  
  script._init();
  
});


