var map = { 
    map: null,
    markers: [],
    _init:function() {},

    mapInit: function( mapSelector ) {
        var self = this;

		return map = new google.maps.Map(mapSelector, {
			center: {lat: 54.406140, lng: 18.661410},
			zoom: 10,
			scrollwheel: false,
			styles: [
				{
					"featureType": "all",
					"stylers": [
						{
							"saturation": 0
						},
						{
							"hue": "#e7ecf0"
						}
					]
				},
				{
					"featureType": "road",
					"stylers": [
						{
							"saturation": -70
						}
					]
				},
				{
					"featureType": "transit",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"stylers": [
						{
							"visibility": "simplified"
						},
						{
							"saturation": -60
						}
					]
				}
			]
		});


    },

    scrollToMap: function(elementToScroll) {
        var aTag = window.scrollY + elementToScroll.getBoundingClientRect().top;
        $('html,body').animate({scrollTop: aTag - 80},'slow');
    },
}

$( document ).ready( function() {
    map._init();
});