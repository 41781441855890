var popupHome = {

    showPopupSelector: '.form-button',
    popupSelector: '.home-popup .pop-up',
    exitButton : '.exit-cross',
    _init: function() {

        this.registerEvents();
        this.hidePopup();
        this.hidePopupClickingAnywhere();

    },

    registerEvents: function() {

        var self = this;

        $(document).mouseup(function(e)  {

            var container = $(".popup-wrapper");

            if (!container.is(e.target) && container.has(e.target).length === 0) {
                self.hidePopup();
            }

        });

        $(document).keyup(function(e){

            if(e.keyCode === 27) {
                self.hidePopup();
            }

        });

    },

    showPopup: function(target) {

        $(this.popupSelector).fadeIn("slow");


    },

    hidePopup: function() {

        $( this.exitButton ).click(function(){
            $('.home-popup .pop-up').fadeOut('slow');
        });

    },

    hidePopupClickingAnywhere: function() {
        $(document).click(function() {
            var container = $("#home-wrapper");
            if (!container.is(event.target) && !container.has(event.target).length) {
                $('.home-popup .pop-up').fadeOut('slow');
            }
        });
    }
};

$( document ).ready( function() {
    popupHome._init();
});

$(document).ready(function(){
});

