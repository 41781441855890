const buttonSidebar = document.querySelector('#sidebar-button');
const wrapperSidebar = document.querySelector('#sidebar-wrapper');


const changePosition = () => {
	wrapperSidebar.classList.toggle('button-click')
	buttonSidebar.classList.toggle('rotate-icon')
}


buttonSidebar.addEventListener('click',() => changePosition())