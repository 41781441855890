var main = {

    _init: function() {

        this.transformSVGToInline();
        this.transformSVGToInlineFast();
        this.goToOperationsCompanies();

    },

    transformSVGToInline: function() {
        setTimeout(function()  {
            $('img.svg').each(function() {
                var $img = $(this);
                var imgID = $img.attr('id');
                var imgClass = $img.attr('class');
                var imgURL = $img.attr('src');

                $.get(imgURL, function(data) {
                    var $svg = $(data).find('svg');

                    if (typeof imgID !== 'undefined')
                        $svg = $svg.attr('id', imgID);

                    if (typeof imgClass !== 'undefined')
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');

                    $svg = $svg.removeAttr('xmlns:a');
                    $img.replaceWith($svg);

                }, 'xml');

            });
        }, 500)

 
    },


    transformSVGToInlineFast: function() {

            $('img.svg').each(function() {
                var $img = $(this);
                var imgID = $img.attr('id');
                var imgClass = $img.attr('class');
                var imgURL = $img.attr('src');

                $.get(imgURL, function(data) {
                    var $svg = $(data).find('svg');

                    if (typeof imgID !== 'undefined')
                        $svg = $svg.attr('id', imgID);

                    if (typeof imgClass !== 'undefined')
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');

                    $svg = $svg.removeAttr('xmlns:a');
                    $img.replaceWith($svg);

                }, 'xml');

            });
    },

    goToOperationsCompanies() {
        
        var checkCompaniesButton = $('body').find('#check-companies-button');
        $(checkCompaniesButton).on('click', function(){
            var portSelect = $('body').find('#choose-port').find(':selected').attr('data-value');
            const URL = $('body').attr('data-root-url') + 'baza-firm-operacyjnych?port=' + portSelect ;
            console.log(URL);
            window.location =  URL ; 
        })
    }

};

$( document ).ready( function() {
    $('img.svg').each(function(element) {
        console.log(this)
    })

    main._init();
});