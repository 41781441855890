$( document ).ready( function() {
    if(window.location.href.indexOf("modul-towarowy") > -1){
        const methods = document.getElementsByClassName('method-button');
        const wrapper = document.querySelector('.element-content-box');
        const headers = wrapper.getElementsByClassName('content-header');
        const goTo = (e) => {
            const id = e.target.id;
            const object = headers[id];
            window.scrollTo({top: wrapper.offsetTop + object.offsetTop -61, behavior: 'smooth',})
        }

        for (let method of methods) {
            method.addEventListener('click',goTo);
        }
    }
});