var contactFormForAuthorities = {

    _init: function() {
    
        if( $('form.contact-for-authorities-form-wrapper').length === 0 ) {
            return true;
        }

        this.registerEvents();

    },

    registerEvents: function() {

        var self = this;

        $('input[name="name"]').keydown( function() {
            self.setFromName();    
        });

        $('input[name="surname"]').keydown( function() {
            self.setFromName();    
        });

    },

    setFromName: function() {

        $('input[name="fromName"]').val( $('input[name="name"]').val() + ' ' +   $('input[name="surname"]').val());
        
    }

};

$( document ).ready( function() {
    contactFormForAuthorities._init();
});