//   var dropdownAfter = window.getComputedStyle(
//     document.querySelector('.dropdown'), ':after'
//     ).getPropertyValue('content');

var menuScripts = {

    _initialize: function() {  
        this.stickeyMenu();
        this.hamburgerEvents();
        // this.menuBottomLine();
        this.markMenuElement(); //podświetlanie wybranego elementu menu
        this.selectInit(); //wybór języków
        this.aboutMenu(); //małe menu na podstronach
        this.toggleDropdownMenu(); //submenu logowania
    },

    stickeyMenu: function() {

        $(document).ready(function() {
            var navTop = $('header .header-bg-img nav').offset().top;     
            var logo = $('header .logo');
            const logos = $('header .logo-refs');
            var logoSticky = $('header .logo-sticky');
            var logoStickyMobile = $('header .logo-sticky-mobile');
            var menuButton = $('header .sidebar-menu-toggle');
            
            var stickyNav = function(){
                var scrollNav = $(window).scrollTop(); 
                if ($(window).width() > 1024) {
                // var dropdownMenu = $('header .header-bg-img nav .drop-down');    
                    if (scrollNav > navTop && scrollNav > 70) { 
                       
                        logo.css({"display":"none"});
                        logoSticky.addClass('logo-sticky-visible');

                        $('header .header-bg-img nav').addClass('sticky');
                        //dropdown menu gradient
                        $('header .menu-wrapper .main-menu .dropdown').addClass('pseudo-no-content');

                        //text color 
                        $('header .menu-wrapper li a').css({"color":"#26009e",
                                                            "font-weight":"400"});
                        //svg color
                        $('header .menu-wrapper li a svg').css({"fill":"#26009e"});
                    
                        $('header .menu-wrapper .panel-menu li .select2-container .select2-selection span:first-child')
                        .css({"color":"#26009e","font-weight":"400"});
                        if( $('header .menu-wrapper .main-menu .dropdown').hasClass('show-up')) {
                            $('header .menu-wrapper .main-menu .white-menu').addClass('display-inline');
                        }
                    } else {
                        logo.css({"display":"inline"});
                        logoSticky.removeClass('logo-sticky-visible');

                        $('header .header-bg-img nav').removeClass('sticky'); 
                        //dropdown menu gradient
                        $('header .menu-wrapper .main-menu .dropdown').removeClass('pseudo-no-content');
                        //remove dropdown white background when scroll back to top
                        $('header .menu-wrapper .main-menu .white-menu').removeClass('display-inline');
                        
                        
                        //text color
                        $('header nav .menu-wrapper li a').css({"color":"white",
                                                                "font-weight":"300"});
                        //svg color
                        $('header .menu-wrapper li a svg').css({"fill":"white"});
                        $('header .menu-wrapper .panel-menu li .select2-container .select2-selection span:first-child').css({"color":"white",
                                                                                                                            "font-weight":"200"});
                    }
                } else {

                    if (scrollNav > navTop && scrollNav > 70) {
                        $('header .header-bg-img nav').addClass('sticky').css({'align-items':'flex-start','background':'transparent','box-shadow':'none'});
                        $('header .header-bg-img nav .rwd-nav-stripe').css({'visibility':'visible'});
                        $('header .header-bg-img nav #box').css({'position':'fixed'});

                        
                        $(logo).css({'display':'none'});
                        menuButton.css({'top':'13px'});
                        logoStickyMobile.css({'display':'block', 'visibility':'visible'});
                        logos.css({'top': '10px'});
                    } else {
                        menuButton.css({'top':'42px'});
                        logos.css({'top': 'unset'});
                        logo.css({'display':'block'});
                        logoStickyMobile.css({'display':'none', 'visibility':'hidden'});
                        $('header .header-bg-img nav .rwd-nav-stripe').css({'visibility':'hidden'});

                    }
                }
            };

            $(window).scroll(function() {
                stickyNav();
            });
        });
    },

    hamburgerEvents: function() {
        var btn = document.querySelector(".sidebar-menu-toggle");
        var menu = document.querySelector("nav");
        var menuWrapper = document.querySelector(".menu-wrapper");
        var tlo = document.querySelector("#box");
        var body = document.querySelector("body");
        var dropdownElements = document.querySelector(".dropdown")
        //hamburger button on/off
        btn.onclick = function() {
            btn.classList.toggle("active");
            menu.classList.toggle("visible");
            tlo.classList.toggle("big-box");
            body.classList.toggle("overflowHidden");
            menuWrapper.classList.toggle("opacity100");
            dropdownElements.classList.remove("show-up");
        };
    },

    menuBottomLine: function() {
        //menu bottom line variables
        var zmiennaZMenu = document.querySelector(".main-menu"); 
        var mainMenuItems = document.querySelector(".main-menu-items");
        var theMenuElement = document.querySelector(".main-menu li");
        var dropdownElements2 = document.querySelector(".dropdown"); 
        var logoSticky = $('header .logo-sticky');
        //lang list
        var langlist = document.querySelector(".panel-menu li:nth-child(4)");
        var whiteMenu = document.querySelector("header .main-menu .white-menu");
        //menu bottom line
        mainMenuItems.onclick = function(){
            if($('.main-menu li .dropdown').length > 0) {
                zmiennaZMenu.classList.toggle("blue-border-bottom");
            }
        //stickyMenu white dropdown
            if ($('.main-menu li .dropdown').length > 0 && logoSticky.hasClass("logo-sticky-visible")){
                whiteMenu.classList.toggle("display-inline");
            }
        };
        //lang list
        langlist.onclick = function() {
            langlist.classList.toggle("blue-border-bottom");
            if (logoSticky.hasClass("logo-sticky-visible")) {
                whiteMenu.classList.toggle("display-inline");
            }
        };
        document.addEventListener("click",function(evt){
            var flyoutElement3 = langlist,
                targetElement3 = evt.target;
            do {
                if (targetElement3 == flyoutElement3){

                    return;
            }
            targetElement3 = targetElement3.parentNode;
        } while (targetElement3);
        
        langlist.classList.remove("blue-border-bottom");
        });

        document.addEventListener("click", function(evt) {
        var flyoutElement2 = zmiennaZMenu,
            targetElement2 = evt.target; 
        do {
            if (targetElement2 == flyoutElement2 || targetElement2 == dropdownElements2 || targetElement2 == langlist) {  
                
                return;
            }
            targetElement2 = targetElement2.parentNode;
        } while (targetElement2);
        zmiennaZMenu.classList.remove("blue-border-bottom");
        whiteMenu.classList.remove("display-inline");
        
        });
    },

    markMenuElement: function() {

        var clickableElement = $(".main-menu-items");
        var allElements = $("ul.dropdown");
    
        clickableElement.click(function(event) {
            var target = $( event.target );
            var pickedMenuItem = target.closest("li").find("ul.dropdown");

            if (pickedMenuItem.hasClass("show-up")) {
            allElements.removeClass("show-up");
            
        } else { 
            allElements.removeClass("show-up");
            pickedMenuItem.addClass("show-up");  
        }
    
            if(pickedMenuItem.length > 0 ){
            target.closest("li").find("a.first-link").removeAttr( "href" );
            
        }
        });
        var scrollPos = 0;
            function checkPosition() {
                var windowY = window.scrollY;
                if ( (windowY !== scrollPos) && ($(window).width() > 1024) ) {
                    allElements.removeClass("show-up");
                    $('header .menu-wrapper .main-menu .white-menu').removeClass('display-inline');
                    $('.main-menu').removeClass('blue-border-bottom');
                }
                scrollPos = windowY; 
            }
        window.addEventListener('scroll', checkPosition);
    },

    selectInit: function() {
        $(".lang-list").select2({
            dropdownAutoWidth: false
        });
        $('.lang-list').on('select2:select', function (e) {
            var data = e.params.data;
            var elem = $(data.element);
            if( $(elem).val()) {
                location.href = elem.val();
            }
        });
    },

    aboutMenu: function(){ 
        var aboutMenuArrow = document.querySelector(".aboutMenuArrow");
        var aboutMenu = document.querySelector(".menu-intro-ul"); 
        
        // aboutMenuArrow.onclick(function() {
        //     aboutMenu.classList.toggle("aboutMenu-OnOff");
        //     aboutMenuArrow.classList.toggle("aboutMenu-borderBottom");
        // });
    },
    toggleDropdownMenu: function() {
        const dropdownLoginPanel = document.querySelector("#dropdown-login-panel");

        dropdownLoginPanel.addEventListener('click', function () {
            dropdownLoginPanel.classList.toggle("dropdown-panel-visible")
        })
    }
};

$(document).ready(function() {

    menuScripts._initialize();
    
});






