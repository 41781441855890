var selectObj = {

    _init: function() {
        var self = this;
        this.selectInit();
        this.onClickHideElement();
        $('body').find('.button-refresh-filter').on('click', function(){
            self.clearSelect();

        });
    },

    selectInit: function() {
        $("#choose-port").select2({
            dropdownAutoWidth: false,
            placeholder: 'Wybierz pozycję',
            dropdownCssClass: "companies-select-dropdown",
            containerCssClass: "companies-select",
            allowClear: true
        });
        $("#choose-type").select2({
            dropdownAutoWidth: false,
            placeholder: 'Wybierz pozycję',
            dropdownCssClass: "companies-select-dropdown",
            containerCssClass: "companies-select",
            allowClear: true
        });

        $(".operations #choose-port").select2({
            dropdownAutoWidth: false,
            placeholder: 'Wybierz pozycję',
            dropdownCssClass: "companies-select-dropdown no-border",
            containerCssClass: "companies-select",
            allowClear: true
        });
    

        $(".movement-select").select2({
            dropdownAutoWidth: false,
            placeholder: 'PORT GDAŃSK',
            dropdownCssClass: "companies-select-dropdown",
            containerCssClass: "companies-select",
            allowClear: true
        });
    },

    onClickHideElement: function() {
        $(".type1").click(function() {
            $(".type2").toggleClass('z-index');
        });
    },

    clearSelect:function() { 
        $(".port-select").val(null).trigger('change');
    }

};

$( document ).ready( function() {
    selectObj._init();
});

