var carrer = {
    
    _init: function() {
        
        this.careerOnClickExpand();
        this.changeableArrow();
        
    },
    
    careerOnClickExpand: function() {
        var clickableOffer = $(".career .container .offers .one-offer");
        
        clickableOffer.click(function(event) {

            var target =  $( event.target );
            var targetedClickableOffer = target.closest(".one-offer");

            if (targetedClickableOffer.hasClass("expand")) {
                clickableOffer.removeClass("expand");
                clickableOffer.find(".bar .headers h2").removeClass("color-open-career-box");
            } else { 
                clickableOffer.find(".bar .headers h2").addClass("color-open-career-box");
                clickableOffer.removeClass("expand");
                targetedClickableOffer.addClass("expand");
              }
        });
    },

    changeableArrow: function() {
        var clicabelElement = $(".career .container .offers .one-offer");
        var arrow = $('.arrow');

            clicabelElement.click(function(){
                $(arrow).toggleClass('arrow-up');
            });
    }

};

$( document ).ready( function() {
    carrer._init();
});