
var moonFleet = {
    rootUrl: $('body').attr('data-root-url'),
    apikey: '308fc763000df895a055b2a84eeb66ca',
    mapSelector: document.getElementById( 'ruch-statkow' ),
    map: {},
    markers: [],
    vesselCategory: {
        'Oceanographic ship': '#990033',
        'Special Purpose': '#ff0033',
        'Sailing ship': '#cc00ff',
        'Container ship': '#cc0066',
        'Liquefied gas carrier': '#cc0099',
        'Cement carrier': '#660099',
        'RoRo ship': '#9966cc',
        'Pusher/Tug': '#6600cc',
        'Heavy lift cargo': '#6633ff',
        'Support vessel': '#6666cc',
        'Dry cargo': '#333399',
        'Trawler': '#0066ff',
        'Dredger': '#336699',
        'Coaster': '#6699cc',
        'LPG tanker': '#3399cc',
        'UNSPECIFIED': '#0099ff',
        'Reefer': '#00cccc',
        'Ferry': '#009999',
        'Bulk carrier': '#005D5D',
        'Oil Products Tanker': '#33cc99',
        'Supply vessel': '#669966',
        'Fishing guard ship': '#33cc66',
        'Vehicle carrier': '#66cc33',
        'CARGO': '#99cc33',
        'Floating crane': '#cccc00',
        'Oil tanker': '#ffcc00',
        'Fire-fighting ship': '#cc9933',
        'Lighter': '#ff9900',
        'Research ship': '#cc6600',
        'Chemical carrier': '#993300',
        'General cargo vessel': '#ff6666',
        'Passenger ship': '#2D270F',
        'Cargo ship': '#939393',
        'Tanker': '#CEE56C',
        'Fishing vessel': '#5FE0E0',

    },
    mapInfoWindow: null,
    livePortData: {},
    _init: function() {

        if(this.mapSelector){
            this.map = this.mapInit(this.mapSelector);
        }

        this.prepareSelect();
        main.transformSVGToInlineFast()
        // this.setLegend();

        this.initGdansk()

    },

    setMarkerColor: function(type){
        switch(type) {
            case "Oceanographic ship":
                return "#990033";
                break;
            case "Special Purpose":
                return "#ff0033";
                break;
            case "Sailing ship":
                return "#cc00ff";
                break;
            case "Container ship":
                return "#cc0066";
                break;
            case "Liquefied gas carrier":
                return "#cc0099";
                break;
            case "Cement carrier":
                return "#660099";
                break;
            case "RoRo ship":
                return "#9966cc";
                break;
            case "Pusher/Tug":
                return "#6600cc";
                break;
            case "Heavy lift cargo":
                return "#6633ff";
                break;
            case "Support vessel":
                return "#6666cc";
                break;
            case "Dry cargo":
                return "#333399";
                break;
            case "Trawler":
                return "#0066ff";
                break;
            case "Dredger":
                return "#336699";
                break;
            case "Coaster":
                return "#6699cc";
                break;
            case "LPG tanker":
                return "#3399cc";
                break;
            case "UNSPECIFIED":
                return "#0099ff";
                break;
            case "Reefer":
                return "#00cccc";
                break;
            case "Ferry":
                return "#009999";
                break;
            case "Bulk carrier":
                return "#005D5D";
                break;
            case "Oil Products Tanker":
                return "#33cc99";
                break;
            case "Supply vessel":
                return "#669966";
                break;
            case "Fishing guard ship":
                return "#33cc66";
                break;
            case "Vehicle carrier":
                return "#66cc33";
                break;
            case "CARGO":
                return "#99cc33";
                break;
            case "Floating crane":
                return "#cccc00";
                break;
            case "Oil tanker":
                return "#ffcc00";
                break;
            case "Fire-fighting ship":
                return "#cc9933";
                break;
            case "Lighter":
                return "#ff9900";
                break;
            case "Research ship":
                return "#cc6600";
                break;
            case "Chemical carrier":
                return "#993300";
                break;
            case "General cargo vessel":
                return "#ff6666";
                break;
            case "Passenger ship":
                return "#2D270F";
                break;
            case "Cargo ship":
                return "#939393";
                break;
            case "Tanker":
                return "#CEE56C";
                break;
            case "Fishing vessel":
                return "#5FE0E0";
                break;
            default:
                return "#0099ff";
        }
    },

    preparePortShips: function(response){

        const shipInfoElement = document.querySelector('#ship-list-element')
        // liczenie wysokosci

        // const sidebarContent= document.querySelector('.sidebar-content-wrapper')
        //
        // let sidebarHeight =  sidebarContent.offsetHeight
        //
        // let shipInfoElementHeightNoPx = (response.length * 22)+10;
        //
        // let shipInfoElementHeightScroll = sidebarHeight-480+'px';
        // shipInfoElement.style.height = shipInfoElementHeightScroll;
        //
        // if(shipInfoElementHeightNoPx > (sidebarHeight-480)){
        //     shipInfoElement.style.overflowY = 'scroll'
        // }else{
        //     shipInfoElement.style.overflowY = 'auto'
        // }



        const names = response.map((item) =>{
            // console.log(item)
            const name = item?.name || '';
            const type = item?.type
            const ID = item?.id || '';
            const image = item?.imageUrl || '';
            const latitude = item?.latitude || '';
            const longitude = item?.longitude || '';
            const color = this.setMarkerColor(type)

            return(
                `<div class="ship-legend-port for-ship-port-list" >
                        <div  class="legend-item-port" style="fill:${color}">
                            <img class="svg" src="dist/img/statek2.svg">
                            <p style="cursor:pointer; width:100%;"                                
                                shipName="${name}" 
                                shipType="${type}" 
                                shipImage="${image}" 
                                shipId="${ID}"  
                                shipColor="${color}"
                                shipLatitude = ${latitude}
                                shipLongitude = ${longitude}
                            >${name}</p>
                        </div>
                    </div>`
            )
        }).join('')
        main.transformSVGToInline();

        shipInfoElement.innerHTML = `<p class="ship-item">${names}</p>`
    },


    openPopup: function(){
        const shipsPort = document.querySelectorAll('.for-ship-port-list');

        shipsPort.forEach(el => el.addEventListener('click', event => {
            const shipId= event.target.attributes.shipId.value || '';
            const shipName= event.target.attributes.shipName.value || '';
            const shipImage= event.target.attributes.shipImage.value || 'dist/img/ruch_statkow_foto.jpg';
            const shipType= event.target.attributes.shipType.value || '';
            const shipColor= event.target.attributes.shipColor.value || '';
            // const shipLatitude= event.target.attributes.shipLatitude.value || '';
            // const shipLongitude= event.target.attributes.shipLongitude.value || '';
            //
            // var shipInfo = {
            //     title: event.target.attributes.shipName.value || '',
            //     type: event.target.attributes.shipType.value || '',
            //     imageUrl: event.target.attributes.shipImage.value || 'dist/img/ruch_statkow_foto.jpg',
            //     id: event.target.attributes.shipId.value || '',
            // };
            //
            // var symbol = {
            //     path: "M8.505,7.974H4.058A3.3,3.3,0,0,1,1.16,6.2L0,3.507l7.032-.015a.659.659,0,0,0,.394-.132.693.693,0,0,0,.247-.345,1.441,1.441,0,0,1,1.358-1.01h0l3.505.014L11.056,6.137a2.812,2.812,0,0,1-1,1.333A2.641,2.641,0,0,1,8.505,7.974ZM8.488,4.765a.859.859,0,1,0,.825.858A.843.843,0,0,0,8.488,4.765ZM6.04,2.052H4.07V0H6.04V2.051Zm-2.948,0H1.122V0H3.092V2.051Z",
            //     fillColor: '#fff',
            //     fillOpacity: 1,
            //     anchor: new google.maps.Point(-3.00, -6.00),
            //     strokeWeight: 0,
            //     scale: 1.1,
            // };
            //
            // var marker = new google.maps.Marker({
            //     position: new google.maps.LatLng(  shipLatitude, shipLongitude ),
            //     map: this.map,
            //     icon: symbol,
            //     zIndex: 1
            // });






            const shipInfoPopup = document.getElementById('ship-info-popup');

                shipInfoPopup.innerHTML =
                    `<div class="ship-info-wrapper">
                                    <img class="ship-info-image" src="${shipImage}" alt="">
                                    <div class="ship-info-text-wrapper">
                                        <p id="ship-info-close" class="ship-info-close">wróć</p>
                                        <p class="ship-info-name">${shipName}</p>
                                        <div class="ship-info-type-wrapper " style="fill:${shipColor}">
                                            <img class="svg" style="width:10px;height:10px;" src="dist/img/statek2.svg">
                                            <p>${shipType}</p> 
                                        </div>
                                     </div>
                                </div>`
                main.transformSVGToInline();


            const shipInfoClose = document.getElementById('ship-info-close');

            shipInfoClose.addEventListener('click',function(){
                shipInfoPopup.innerHTML = ``})


        }));
    },

    showFilteredCategories: function(filteredCategories,res,selectedShips){
        const selectedCategoriesItems =  filteredCategories.map((item,key) =>{

            res.map((resItem) =>{
                if (resItem.type === item) {
                    selectedShips.push(resItem)
                }
            })



            const color = this.setMarkerColor(item)

            return(
                '<div class="legend-item-port"  style="fill:' + color + ' ">' +
                '<div class="text-wrapper">' +
                '<img class="svg" src="dist/img/statek2.svg">' +
                '<label> ' + item +   '</label>' +
                '</div>'+
                '<img dataType="'+ item +'" class="btn-delete-from-filtered" src="dist/img/ruch-statkow/button-delete-from-filtered.svg" />' +
                '</div>'
            )

        }).join(' ')
        main.transformSVGToInline();

        const selectedCategoriesWrapper = document.getElementById('selected-categories-wrapper');

        selectedCategoriesWrapper.classList.add('line')

        selectedCategoriesWrapper.innerHTML = `<div>${selectedCategoriesItems}</div>`;


        const shipPortVisbility = document.querySelector('#ship-port-visbility');
        shipPortVisbility.classList.remove('hidden')


        this.setShipsCounter(selectedShips.length)




        const btnDeleteFromFiltered =  document.querySelectorAll('.btn-delete-from-filtered')



        btnDeleteFromFiltered.forEach(el => el.addEventListener('click', event => {
            event.preventDefault()
            const clickedItemParentEvent = event;
            console.log(clickedItemParentEvent)
            // document.querySelectorAll('[dataType="value"]');

            // <div class="legend-item-port" data

            const clickedItemParent = event.target.parentElement;








            let value = event.target.attributes.dataType.value

            const index = filteredCategories.indexOf(value);

            if (index > -1) {
                filteredCategories.splice(index, 1);
            }

            const selectedShips = []

            this.showFilteredCategories(filteredCategories,res,selectedShips)

            this.preparePortShips(selectedShips)

            this.setShipsCounter(selectedShips.length)

            var self = this;

            this.onlyPrepareMarkers(self,selectedShips)

            this.openPopup()



            if(filteredCategories.length === 0){
                this.cleanFiltration(res,filteredCategories)
                this.onlyPrepareMarkers(self,res)
            }

            const dataTypeElement = event.target.parentElement.firstChild.lastChild.innerText;

            const dataTypeItem = document.querySelector(`[dataType=${CSS.escape(dataTypeElement.trim())}]`)

            dataTypeItem.classList.remove('hidden')

        }))
    },

    cleanFiltration: function(response,selectedCategories){

            const selectedCategoriesWrapper = document.getElementById('selected-categories-wrapper');
            const shipLegendWrapper = document.querySelector('.ship-legend-wrapper');
            const shipPortVisbility = document.querySelector('#ship-port-visbility');
            const btnDeleteFilter = document.querySelector('#btn-delete-filter');
            const btnMoreShipsFilter = document.getElementById('btn-more-ships-filter');
            // const legendItem = document.querySelectorAll('.legend-item-port');



            Array.from(document.querySelectorAll('.legend-item-port')).forEach((el) => el.classList.remove('hidden'));
            selectedCategories.length = 0
            btnDeleteFilter.classList.add('hidden')
            selectedCategoriesWrapper.classList.remove('line')
            selectedCategoriesWrapper.classList.remove('margin-top')
            selectedCategoriesWrapper.innerHTML = ` `;
            shipPortVisbility.classList.remove('hidden')
            shipLegendWrapper.classList.add('hidden')


            if(btnMoreShipsFilter.classList.contains('filter-close')){
                btnMoreShipsFilter.classList.add('filter')
                btnMoreShipsFilter.classList.remove('filter-close')
            }

            this.setShipsCounter(response.length)

            this.preparePortShips(response)

            this.openPopup()
    },

    showErrorModal(){
        var errorModal = $('body').find('.error-modal').addClass('visible');
    },

    setItemInLegend:function(categories){
        const legendWrapper = document.querySelector('.ship-legend-wrapper')
        const categoryTemplate = Object.entries(this.vesselCategory).map(function([key, value]){

            if (categories.includes(key)) {

                return(
                    '<div class="legend-item-port" dataType="'+ key +'"  style="fill:' + value + ' ">' +
                    '<div class="text-wrapper" dataType="'+ key +'" >' +
                    '<img  class="svg" src="dist/img/statek2.svg">' +
                    '<label style="color:' + '#ddd' +'" dataType="'+ key +'" > ' + key + '</label>' +
                    '</div>'+

                    '</div>'

                )
            }}
        ).join('');

        legendWrapper.innerHTML = `<div>${categoryTemplate}</div>`
    },

    setLegend:function(res) {

        const categories = []

        const legendWrapper = document.querySelector('.ship-legend-wrapper')

        res.map((item) =>{
           if (!categories.includes(item.type)) {
               categories.push(item.type)
           }

        })

        this.setItemInLegend(categories)

        main.transformSVGToInline();

        const legendItem = document.querySelectorAll('.legend-item-port');

        const selectedCategories = []

        legendItem.forEach(el => el.addEventListener('click', event => {
            event.preventDefault()

            // console.log(event)
            // const clickedItem = event.target.attributes.dataType.value;
            const clickedItemParent = event.target.parentElement.parentElement;
            clickedItemParent.classList.add('hidden')
            // console.log(clickedItem)

            function cleanFilter2(){
                const shipLegendWrapper = document.querySelector('.ship-legend-wrapper');
                const shipPortVisbility = document.querySelector('#ship-port-visbility');
                const selectedCategoriesWrapper = document.querySelector('#selected-categories-wrapper');


                selectedCategoriesWrapper.classList.add('line')
 
                shipPortVisbility.classList.remove('hidden')
                selectedCategoriesWrapper.classList.add('line')

            }

            cleanFilter2()

            const shipTextWrapper = document.querySelector('.ship-text-wrapper');
            shipTextWrapper.classList.remove('hidden')

            const selectedCategory = event.target.attributes.dataType.value;

            if (!selectedCategories.includes(selectedCategory)) {
                selectedCategories.push(selectedCategory)
            }

            const selectedShips = []

            this.showFilteredCategories(selectedCategories,res,selectedShips)

            this.preparePortShips(selectedShips)

            this.openPopup()

            this.setShipsCounter(selectedShips.length)

            var self = this;

            this.onlyPrepareMarkers(self,selectedShips)

            // this.leg

            //    usuwanie wybranego typu
            // this.deleteItemFromCategoryList(res,selectedCategories,categories)
            //end

        }));

        const cleanFiltration = (response) => {
            const selectedCategoriesWrapper = document.getElementById('selected-categories-wrapper');
            const shipLegendWrapper = document.querySelector('.ship-legend-wrapper');
            const shipPortVisbility = document.querySelector('#ship-port-visbility');
            const btnDeleteFilter = document.querySelector('#btn-delete-filter');
            const btnMoreShipsFilter = document.getElementById('btn-more-ships-filter');
            // const legendItem = document.querySelectorAll('.legend-item-port');

            Array.from(document.querySelectorAll('.legend-item-port')).forEach((el) => el.classList.remove('hidden'));
            selectedCategories.length = 0
            btnDeleteFilter.classList.add('hidden')
            selectedCategoriesWrapper.classList.remove('line')
            selectedCategoriesWrapper.classList.remove('margin-top')
            selectedCategoriesWrapper.innerHTML = ` `;
            shipPortVisbility.classList.remove('hidden')
            shipLegendWrapper.classList.add('hidden')


            if(btnMoreShipsFilter.classList.contains('filter-close')){
                btnMoreShipsFilter.classList.add('filter')
                btnMoreShipsFilter.classList.remove('filter-close')
            }

            this.preparePortShips(response)
            this.setShipsCounter(response.length)
            this.openPopup()


        }

        const self = this


        const btnCleanFilter = document.getElementById('btn-delete-filter');

        btnCleanFilter.addEventListener('click',() => this.cleanFiltration(res,selectedCategories))
        btnCleanFilter.addEventListener('click',() => this.onlyPrepareMarkers(self,res))

        const btnMoreShipsFilter = document.getElementById('btn-more-ships-filter');

        function moreShipsFilter(event){
            const shipLegendWrapper = document.querySelector('.ship-legend-wrapper');
            const shipPortVisbility = document.querySelector('#ship-port-visbility');
            const selectedCategoriesWrapper = document.getElementById('selected-categories-wrapper');
            const btnDeleteFilter = document.querySelector('#btn-delete-filter');

            const btnMoreShipsFilter = document.getElementById('btn-more-ships-filter');

            btnDeleteFilter.classList.remove('hidden')

            selectedCategoriesWrapper.classList.add('margin-top')


            if(selectedCategoriesWrapper.classList.contains('line')){
                selectedCategoriesWrapper.classList.remove('line')

            }else{
                selectedCategoriesWrapper.classList.add('line')

            }



                if(btnMoreShipsFilter.classList.contains('filter-close')){
                    btnMoreShipsFilter.classList.remove('filter-close')
                }else{
                    btnMoreShipsFilter.classList.add('filter-close')
                }

                if(btnMoreShipsFilter.classList.contains('filter')){
                    btnMoreShipsFilter.classList.remove('filter')
                }else{
                    btnMoreShipsFilter.classList.add('filter')
                }


            if(shipLegendWrapper.classList.contains('hidden')){
                shipLegendWrapper.classList.remove('hidden')

            }else{
                shipLegendWrapper.classList.add('hidden')

            }

        }


        const filterWithStopEvents = function (event) {
            moreShipsFilter(event);
            event.stopPropagation();
            event.preventDefault();

        }

        btnMoreShipsFilter.addEventListener('click',filterWithStopEvents );


        var select = $('body').find('.movement-select');
        $(select).on('change', function() {

            btnMoreShipsFilter.removeEventListener('click', filterWithStopEvents );


            const shipPortVisbility = document.querySelector('#ship-port-visbility');
            const selectedCategoriesWrapper = document.getElementById('selected-categories-wrapper');


            selectedCategoriesWrapper.classList.remove('line')
            shipPortVisbility.classList.remove('hidden')

            cleanFiltration(res)
        });



    },

    deleteItemFromCategoryList: function(res,selectedCategories,categories){

        res.map((item) =>{
            console.log(item)
            console.log(selectedCategories)
            console.log(categories)
            if (selectedCategories.includes(item.type)) {
                let chooseItem = item.type
                console.log(chooseItem)
                const chooseItemIndex = categories.indexOf(chooseItem)
                console.log(chooseItemIndex)

                for( var i = 0; i < categories.length; i++){

                    if ( categories[i] === chooseItem) {
                        categories.splice(i, 1);
                        i--;
                    }
                }


                console.log(categories)

                this.setItemInLegend(categories)
                // this.setLegend(res)

                main.transformSVGToInline();

                const legendItem = document.querySelectorAll('.legend-item-port');
                legendItem.forEach(el => el.addEventListener('click', event => {
                    event.preventDefault()

                    function cleanFilter2(){
                        const shipLegendWrapper = document.querySelector('.ship-legend-wrapper');
                        const shipPortVisbility = document.querySelector('#ship-port-visbility');
                        const selectedCategoriesWrapper = document.querySelector('#selected-categories-wrapper');


                        selectedCategoriesWrapper.classList.add('line')

                        shipPortVisbility.classList.remove('hidden')
                        selectedCategoriesWrapper.classList.add('line')

                    }

                    cleanFilter2()

                    const shipTextWrapper = document.querySelector('.ship-text-wrapper');
                    shipTextWrapper.classList.remove('hidden')

                    const selectedCategory = event.target.attributes.dataType.value;

                    if (!selectedCategories.includes(selectedCategory)) {
                        selectedCategories.push(selectedCategory)
                    }

                    const selectedShips = []

                    this.showFilteredCategories(selectedCategories,res,selectedShips)

                    this.preparePortShips(selectedShips)

                    this.openPopup()

                    this.setShipsCounter(selectedShips.length)

                    var self = this;

                    this.onlyPrepareMarkers(self,selectedShips)


                    //    usuwanie wybranego typu
                    this.deleteItemFromCategoryList(res,selectedCategories,categories)
                    //end

                }));


            }

        })
    },

    mapInitialize: function () {

        this.map = new google.maps.Map(document.getElementById( 'ruch-statkow' ), {

            center: {lat: 54.505828, lng: 17.593},
            zoom: 8,
            scrollwheel: false,
            mapTypeControlOptions: {
                mapTypeIds: ["roadmap", "satellite", "hybrid", "terrain", "styled_map"],
            },


        });
    },


    
    prepareSelect:function(){

        var self = this;
        var select = $('body').find('.movement-select');

        $(select).on('change', function() {
            self.clearAllMarkers();
            var lat = $(select).find(':selected').attr('data-lat');
            var lng = $(select).find(':selected').attr('data-lng');
            var locode = $(select).find(':selected').attr('data-locode');
            
            self.zoomToPort(lat, lng);
            self.scrollToMap(self.mapSelector);
            self.getInfoPorts(locode);

        });
    },

    initGdansk:function(){
        this.zoomToPort(54.406556, 18.661056);
        this.scrollToMap(this.mapSelector);
        this.getInfoPorts('GDN');
    },
    
    zoomToPort: function(lat,lng){
        this.map.setCenter(new google.maps.LatLng(lat, lng));
        this.map.setZoom(13);
    },
    
    getInfoPorts:function(locode){
        var self = this;
        var spinner = $('body').find('.loading-blenda');
        $(spinner).addClass('visible');
        self.mapInfoWindow = new google.maps.InfoWindow();
        $.ajax({
            type: "get",
            // url: "http://demo.pub.dev.polskipcs.pl/gateway/port/" + locode  + "/arrival",
            url: "https://demo.polskipcs.pl/gateway/port/" + locode  + "/arrival",


            success: function(res) {
                loadGet()
                self.setLegend(res);
                self.livePortData = res;
                self.prepareMarkers(res);
                self.mapInfoWindow.close();
                self.preparePortShips(res)

                const contentAfterPortChoose = document.querySelector('#content-after-port-choose');
                contentAfterPortChoose.classList.remove('hidden')

                self.openPopup()

                self.setShipsCounter(res.length);
                $(spinner).removeClass('visible');



            },

            error:function(error) {
                $(spinner).removeClass('visible');
                var modal = setTimeout( self.showErrorModal, 1500);
            }
        });
    },



    prepareMarkers:function(response) {

        this.preparePortShips(response)

        var self = this;
        this.onlyPrepareMarkers(self, response)

    },


    onlyPrepareMarkers: function(self, shipsArray){
        self.clearAllMarkers();

        shipsArray.map(function(element, index) {
            var shipInfo = {
                title: element.name,
                type: element.type,
                imageUrl: element.imageUrl,
                id: element.id
            };
            self.setMarker( new google.maps.LatLng(  element.latitude, element.longitude ), shipInfo, index );
        });
    },

    setShipsCounter:function (value){
        // var date = new Date();
        // var shipsCounter = $('body').find('.ship-movement-info .info-details span');
        // var hoursCounter = $('body').find('.content-title span');
        // var currentDate = `${date.toJSON().slice(0,10).replace(/-/g,'.')} - ${date.getHours()}:${date.getMinutes()}`;
        // $(shipsCounter).text(value);
        //
        // $(hoursCounter).text(currentDate);

        const activeShipsInPort = document.querySelector('.active-ships-in-port');

        activeShipsInPort.innerHTML = `<p>Aktywne statki: ${value}</p>`

    },
    
    bindInfoWindow : function ( marker, map, shipInfo ) {
        // console.log(marker)
        // console.log(map)
        // console.log(shipInfo)
        var html = this.prepareHtml(shipInfo);
        var shipList = this.prepareHtml(shipInfo);
        var self = this;


        const shipImage= shipInfo.imageUrl || 'dist/img/ruch_statkow_foto.jpg';

        const shipInfoPopup = document.getElementById('ship-info-popup');

        const color = this.setMarkerColor(shipInfo.type)
        function openPopup(){
            shipInfoPopup.innerHTML =
                `<div class="ship-info-wrapper">
                                    <img class="ship-info-image" src="${shipImage}" alt="">
                                    <div class="ship-info-text-wrapper">
                                        <p id="ship-info-close" class="ship-info-close">wróć</p>
                                        <p class="ship-info-name">${shipInfo.title}</p>
                                        <div class="ship-info-type-wrapper " style="fill:${color}">
                                            <img class="svg" style="width:10px;heigth:10px;" src="dist/img/statek2.svg">
                                            <p>${shipInfo.type}</p> 
                                        </div>
                                     </div>
                                </div>`
        }


        marker.addListener( 'click', function() {

            const sidebar = document.querySelector('.sidebar-ship-movement-wrapper');
            const sidebarIcon = document.querySelector('.sidebar-button');

            if (sidebar.classList.contains('button-click')){
                sidebar.classList.remove('button-click')
            }

            if (sidebarIcon.classList.contains('rotate-icon')){
                sidebarIcon.classList.remove('rotate-icon')
            }

            self.mapInfoWindow.close();
            self.mapInfoWindow.setContent( html );
            self.mapInfoWindow.setContent( shipList );
            self.mapInfoWindow.open( map, this );

            openPopup()
            main.transformSVGToInlineFast()
            const shipInfoClose = document.getElementById('ship-info-close');

            shipInfoClose.addEventListener('click',function(){
                shipInfoPopup.innerHTML = ``;
                self.mapInfoWindow.close();
            })

            
        });

        
    },




    
    prepareHtml: function(shipInfo) { 
        var image = shipInfo.imageUrl ? shipInfo.imageUrl.replace(/ /g, ''): this.rootUrl + 'dist/img/ruch_statkow_foto.jpg';
        var shipType = shipInfo.type ? shipInfo.type : 'brak danych';


        const color = this.setMarkerColor(shipInfo.type)



        return '<div class="object-data" >' +
            '<div  class="for-img" style="fill:'+color+'">' +
            '<img class="svg" src="dist/img/statek2.svg">' +
            '</div>' +
        '	<div  class="title "  data-id="'+shipInfo.id+'">' + '<span class="value">' + shipInfo.title + '</span>' +  '</div>' +

        '</div>';



    },


    setMarker: function (positionObject, shipInfo, index) {
        var infoWindow = new google.maps.InfoWindow();
        var markerImg = this.vesselCategory[shipInfo.type]; 

        if(!markerImg){
            markerImg = '#0731A5';
        }


        var symbol = {  
            path: "M8.505,7.974H4.058A3.3,3.3,0,0,1,1.16,6.2L0,3.507l7.032-.015a.659.659,0,0,0,.394-.132.693.693,0,0,0,.247-.345,1.441,1.441,0,0,1,1.358-1.01h0l3.505.014L11.056,6.137a2.812,2.812,0,0,1-1,1.333A2.641,2.641,0,0,1,8.505,7.974ZM8.488,4.765a.859.859,0,1,0,.825.858A.843.843,0,0,0,8.488,4.765ZM6.04,2.052H4.07V0H6.04V2.051Zm-2.948,0H1.122V0H3.092V2.051Z",
            fillColor: '#fff',
            fillOpacity: 1,
            anchor: new google.maps.Point(-3.00, -6.00),
            strokeWeight: 0,
            scale: 1.1,
        };
        symbol2 = {  
            path: "M0,50a50,50 0 1,0 100,0a50,50 0 1,0 -100,0",
            fillColor: markerImg,
            fillOpacity: 1,
            anchor: new google.maps.Point(0, 0),
            strokeWeight: 2.5,
            strokeColor:'#fff',
            scale: 0.21,
        };



        var marker = new google.maps.Marker({
            position: positionObject,
            map: this.map,
            icon: symbol,
            zIndex: 1 + index
        });

        var shadowMarker = new google.maps.Marker({
            position: positionObject,
            map: this.map,
            icon: symbol2,
            zIndex: 0 + index,
            size: new google.maps.Size(15, 10),
        });



            this.markers.push(marker, shadowMarker);
            this.bindInfoWindow(marker, this.map, shipInfo );





        },
        
        clearAllMarkers: function(){
            if (this.markers) {
                for (i in this.markers) {
                    this.markers[i].setMap(null);
                }
            }
        },
        
    };
    
    $(document).ready(function() {
        Object.setPrototypeOf(moonFleet, map);
        moonFleet._init();
    });
    
    
    