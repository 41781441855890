//
//
// setTimeout(function() {
// 	console.log('xd')
// 	const markerItem = document.querySelector('#company-click');
// 	const wrapperItem = document.querySelector('.company-wrapper');
//
// 	function changeColor(){
// 		wrapperItem.classList.add('changeColor')
// 	}
// 	function changeColorRemove(){
// 		wrapperItem.classList.remove('changeColor')
// 	}
//
// 	markerItem.addEventListener("mouseover", () => changeColor())
// 	markerItem.addEventListener("mouseout", () => changeColorRemove())
//
// 	callback();
// }, 6000);
//
//
