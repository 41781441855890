var popupContact = {

    showPopupSelector: '.form-button',
    popupSelector: '.contact-popup .pop-up',
    exitButton : '.contact-popup .pop-up .exit-cross',
    _init: function() {

        this.registerEvents();
        this.hidePopup();
        this.hidePopupClickingEnywhere();

    },

    registerEvents: function() {

        var self = this;

        $(document).mouseup(function(e)  {

            var container = $(".popup-wrapper");

            if (!container.is(e.target) && container.has(e.target).length === 0) {
                self.hidePopup();                
            }

        });
    
        $(document).keyup(function(e){

            if(e.keyCode === 27) {
                self.hidePopup();    
            }

        });

    },
    
    showPopup: function(target) {

        $(this.popupSelector).fadeIn("slow");


    },

    hidePopup: function() {
        
        $( this.exitButton ).click(function(){  
            $('.contact-popup .pop-up').fadeOut('slow');
        });

    },

    hidePopupClickingEnywhere: function() {

        $( '.contact-popup .pop-up' ).click(function(){  
            $('.contact-popup .pop-up').fadeOut('slow');
        });

    }

};

$( document ).ready( function() {
    popupContact._init();
});

$(document).ready(function(){
});

