var contactMapCenter = {

    scrollToMapButton: 'a[href="#map"]',
    mapAnchor: '.general-contact-wrapper',

    _init: function() {

        this.registerEvents();

    },

    registerEvents: function() {

        var self = this;

        $('input[name="direction"]').change(function() {

            var checkedInput = $('input[name="direction"]:checked');
            var value = checkedInput.val();
            var key = $( checkedInput ).data('key');

            var article = $( 'article.adress[data-key=' + key + ']' );
            
            $('article.adress').not( article ).removeClass('visible');
            article.addClass('visible');

            self.centerMap(value);

        });

        $(this.scrollToMapButton).click(function() {

            self.scrollToMap();

        });

    },

    scrollToMap: function() {

        var aTag = $(this.mapAnchor);
        $('html,body').animate({scrollTop: aTag.offset().top},'slow');


    },

    centerMap: function( latLngString ) {

        var splitted = latLngString.split('|');
        var lat = parseFloat(splitted[0]);
        var lng = parseFloat(splitted[1]);

        map.panTo(new google.maps.LatLng(lat, lng));

    }

};

$( document ).ready( function() {
    contactMapCenter._init();
});